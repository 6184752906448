






















































































































































































































































































import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  ValidationRule,
} from 'vuelidate/lib/validators'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import CustomTextInput from './CustomTextInput/index.vue'
import controller, { HasChangedType } from '@/app/ui/controllers/ManageCourierController'
import {
  Utils
} from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import WarningIcon from "@/app/ui/assets/warning_icon_modal.vue";
import {
  EnumOptions,
  EnumTypeCourier,
  EnumTypeVehicle,
} from '@/app/infrastructures/misc/Constants/manageCourier'
import { Partner, TruckVendor } from '@/domain/entities/ManageCourier'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { EnumOption, IFormType, IParams } from '@/data/infrastructures/misc/interfaces/manageCourier'
import InputPhoneInternational from '@/app/ui/components/InputPhoneInternational/index.vue'
import InputSuggestion from '../../components/InputSuggestion/index.vue'
import ManageCourierCheckbox from '../../components/ManageCourierCheckbox/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'

export interface IValidations {
  form: {
    driverName: {
      required: () => ValidationRule
    }
    phoneNumber: {
      required: () => ValidationRule,
      maxLength: () => boolean,
      minLength: () => boolean
    }
    courierType: {
      required: () => ValidationRule
    }
    announcementCourierType: {
      required: () => ValidationRule
    }
    vehicleType: {
      required: () => ValidationRule
    }
    vehiclePlate: {
      required: () => ValidationRule
    }
    partnerName: {
      required: () => ValidationRule
    }
    bankAccountName: {
      required: ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
      isCharactersAllowed: (value: string) => boolean
    }
    bankName: {
      required: ValidationRule
    }
    bankAccount: {
      required: ValidationRule
    },
    vendorId: {
      maxLength: ValidationRule
    },
    vendorName: {
      required: ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    },
    courierIsCod: {
      required: () => ValidationRule
    },
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    TextInput,
    CustomTextInput,
    Modal,
    WarningIcon,
    LoadingOverlay,
    InputPhoneInternational,
    InputSuggestion,
    ManageCourierCheckbox,
    RadioButton
  },
})
export default class AddCourier extends Vue  {
  controller = controller
  confirmationModal = false
  created(): void {
    this.fetchPartnersData()
  }

  get bankAccountNameErrorMessage(): string {
    if (!this.form.bankAccountName) {
      return 'Nama Akun Bank harus diisi'
    } else if (!this.isCharactersAllowed(this.form.bankAccountName)) {
      return 'Nama penerima hanya diperbolehkan spasi, alfanumerik, karakter "." dan ","'
    }
    return 'Nama Akun Bank harus antara 3 - 30 karakter'
  }

  get vendorIdErrorMessage(): string {
    return 'Vendor ID maks 50 karakter'
  }

  get vendorNameErrorMessage(): string {
    if (!this.form.vendorName) {
      return 'Nama Vendor harus diisi'
    }
    return 'Nama Vendor harus antara 3 - 50 karakter'
  }

  optionTabs = [
    {
      label: 'Iya',
      value: 'true',
      name: 'yes',
    },
    {
      label: 'Tidak',
      value: 'false',
      name: 'no',
    }
  ]

  // partnerIsCodDelivery = true;

  form: IFormType = {
    driverName: "",
    phoneNumber: "",
    courierType: [] as unknown as EnumOption ,
    announcementCourierType: [] as unknown as EnumOption,
    vehicleType: [] as unknown as EnumOption,
    vehiclePlate: "",
    partnerName: [] as unknown as EnumOption,
    bankAccountName: "",
    bankName: [] as unknown as EnumOption,
    bankAccount: "",
    phoneCode: "+62",
    isHaveVendor: false,
    vendorId: "",
    vendorName: "",
    courierIsCod: "",
  }
  hasChanged: HasChangedType = {
    driverName: false,
    phoneNumber: false,
    courierType: false,
    announcementCourierType: false,
    vehicleType: false,
    vehiclePlate: false,
    partnerName: false,
    bankAccountName: false,
    bankName: false,
    bankAccount: false,
    vendorId: false,
    vendorName: false,
    courierIsCod: false,
  }
  parameters: IParams = {
    search: '',
    agentType: this.form.courierType
  }
    courierType: EnumOptions = [
        {label: "KVP", value: "KVP"},
        {label: "POS", value: "POS"},
        {label: "Truck", value: "TRUCK"},
        {label: "Internal", value: EnumTypeCourier.INTERNAL},
    ]
  get announcementCourierType() {
    if (this.isCourierTypeInternal) {
      return [{label: "Internal", value: EnumTypeCourier.INTERNAL}]
    }
    return [
      {label: "KVP", value: "KVP"},
      {label: "POS", value: "POS"},
      {label: "Truck", value: "TRUCK"},
    ]
  }
    vehicleType: EnumOptions  = [
        {label: "Motorcycle", value: "MOTORCYCLE"},
    ]
    vehicleTypeTruck: EnumOptions  = [
        {label: "Truck", value: "TRUCK"}
    ]
    bankList: EnumOptions  = [
        {label: "BCA", value: "BCA"},
        {label: "Mandiri", value: "MANDIRI"},
        {label: "Maybank", value: "MAYBANK"},
        {label: "BRI", value: "BRI"},
    ]
    partnerList: EnumOptions = []

    vendorIdOptions: EnumOptions = []
    vendorNameOptions: EnumOptions = []

  get isVehicleTypeTruck(): boolean {
    if (this.form.vehicleType && this.form.vehicleType.value) {
      return (this.form.vehicleType.value as string).toUpperCase() === EnumTypeVehicle.TRUCK
    }
    return false
  }

  get isCourierTypeTruck(): boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.TRUCK
    }
    return false
  }

  get isCourierTypeInternal(): boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.INTERNAL
    }
    return false
  }


  get isShowCOD(): boolean {
    if (this.form.courierType.value && this.form.partnerName) return true
    return false
  }

  get isCourierTypeKVP():boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.KVP
    }
    return false
  }

  get params(): Record<string, string> {
    return {
      search: this.parameters.search.toUpperCase(),
      agent_type: this.form.courierType.value
    }
  }

  private maxLengthIndonesianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phoneNumber.length <= 13
    }
    return true
  }

  private minLengthIndosianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phoneNumber.length >= 9
    }
    return true
  }

  @Validations()
  validations(): IValidations {
        return {
          form: {
            driverName: { required },
            phoneNumber: {
              required,
              maxLength: this.maxLengthIndonesianPhoneNumber,
              minLength: this.minLengthIndosianPhoneNumber
            },
            courierType: { required },
            announcementCourierType: { required },
            vehicleType: { required },
            vehiclePlate: { required },
            partnerName: { required },
            bankAccountName: {
              required: requiredIf(() => {
                if (this.form.courierType.value) {
                  return (this.form.courierType.value as string).toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
                }
                return false
              }),
              minLength: minLength(3),
              maxLength: maxLength(30),
              isCharactersAllowed(value: string): boolean {
                return this.isCharactersAllowed(value)
              }
            },
            bankName: { required: requiredIf(() => {
              if (this.form.courierType.value) {
                return (this.form.courierType.value as string).toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
              }
              return false
            })},
            bankAccount: { required: requiredIf(() => {
              if (this.form.courierType.value) {
                return (this.form.courierType.value as string).toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
              }
              return false
            })},
            vendorId: {
              maxLength: maxLength(50),
            },
            vendorName: {
             required: requiredIf(() => {
                if (this.form.courierType.value) {
                  return (this.form.courierType.value as string).toUpperCase() == EnumTypeCourier.TRUCK && this.form.isHaveVendor
                }
                return false
              }),
              minLength: minLength(3),
              maxLength: maxLength(50),
            },
            courierIsCod: { required },
          },
        }
  }

  private fetchPartnersData(): void {
    if (this.params.search && this.params.agent_type) {
      controller.getAllPartner(this.params)
    }
  }

  private onSearchPartnerName = Utils.debounce((e: string) => {
    this.parameters.search = e
    this.fetchPartnersData()
  }, 400)

  private onSelectCourierType(): void {
    this.hasChanged.courierType = true
    this.form.vehicleType = this.vehicleType[-1]
    this.form.partnerName = this.partnerList[-1]
    this.partnerList = []
    this.fetchPartnersData()

    if (this.form.courierType.value !== EnumTypeCourier.TRUCK) {
      this.form.isHaveVendor = false
    }


    if (this.form.announcementCourierType.value === EnumTypeCourier.INTERNAL) {
      this.form.announcementCourierType = [] as unknown as EnumOption
    }
    if (this.isCourierTypeInternal) {
      this.form.announcementCourierType = {label: "Internal", value: EnumTypeCourier.INTERNAL}
    }

    if (this.form.courierType.value === EnumTypeCourier.POS || this.form.courierType.value === EnumTypeCourier.INTERNAL) this.form.courierIsCod = ''
    else this.form.courierIsCod = 'false'
  }

  public onCheckIsHaveVendor(): void {
    this.form.isHaveVendor = !this.form.isHaveVendor

    if (!this.form.isHaveVendor) {
      this.form.vendorId = ''
      this.form.vendorName = ''
      this.vendorIdOptions = []
      this.vendorNameOptions = []
    }
  }

  public onChangeVendorID = Utils.debounce(() => {
    this.hasChanged.vendorId = true
    this.getVendorSuggestions('id')
  }, 400)

  public onChangeVendorName = Utils.debounce(() => {
    this.hasChanged.vendorName = true
    this.getVendorSuggestions('name')
  }, 400)

  private getVendorSuggestions(type: string): void {
    if (
      (type === 'id' && !this.form.vendorId) ||
      (type === 'name' && !this.form.vendorName)
    ) {
      return
    }
    this.controller.getTruckVendorList({
      params: {
        [`vendor_${type}`]: type === 'id' ?
          this.form.vendorId :
          this.form.vendorName
      },
      type
    })
  }

  private onAddCourier(): void {
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      this.controller.createCourier({
        fullname: this.form.driverName,
        phoneNumber: this.form.phoneCode + this.form.phoneNumber,
        courierType: this.form.courierType.value,
        vehicleType: this.form.vehicleType.value,
        vehiclePlate: this.form.vehiclePlate,
        partnerName: this.form.partnerName.value,
        bankAccountName: this.form.bankAccountName,
        bankId: this.form.bankName.value,
        bankAccount: this.form.bankAccount,
        announcementCourierType: this.form.announcementCourierType?.value || "",
        truckVendor: {
          vendorId: this.form.vendorId,
          vendorName: this.form.vendorName
        },
        courierIsCod: this.form.courierIsCod,
      })
    }

    this.hasChanged = {
        driverName: true,
        phoneNumber: true,
        courierType: true,
        announcementCourierType: true,
        vehicleType: true,
        vehiclePlate: true,
        partnerName: true,
        bankAccountName: true,
        bankName: true,
        bankAccount: true,
        vendorId: true,
        vendorName: true,
        courierIsCod: true,
    }
  }

  private isCharactersAllowed(str: string): boolean {
    // this regex below will check character other than the allowed character
    // input only allow space, alphanumeric, comma (,) and period (.)
    const regex = /([^a-zA-Z0-9., ])+/g

    // return is negated for vuelidate
    return !regex.test(str)
  }

  private onPhoneCodeChange(code: string): void {
    this.form.phoneCode = code
  }

  @Watch('controller.partnerData')
  setPartnerData(data: Partner[]): void {
    const res = data.map((item) => {
      return {
        label: `${item.name} [${item.agentCode}]`,
        value: `${item.name} [${item.agentCode}]`
      }
    })

    this.partnerList = res
  }

  @Watch('form.driverName')
  onChangeDriverName(data: string): void {
    // remove script tags
    this.form.driverName = Utils.removeScriptTags(data)

    // remove special characters
    this.form.driverName = Utils.removeSpecialCharacters(this.form.driverName)

    // remove html tags
    this.form.driverName = Utils.removeHTMLTags(this.form.driverName)
  }

  @Watch('form.phoneNumber')
  onChangePhoneNumber(data: string): void {
    // remove script tags
    this.form.phoneNumber = Utils.removeScriptTags(data)

    // remove special characters
    this.form.phoneNumber = Utils.removeSpecialCharacters(this.form.phoneNumber)

    // remove html tags
    this.form.phoneNumber = Utils.removeHTMLTags(this.form.phoneNumber)
  }

  @Watch('form.vehiclePlate')
  onChangeVehiclePlate(data: string): void {
    // remove script tags
    this.form.vehiclePlate = Utils.removeScriptTags(data)

    // remove special characters
    this.form.vehiclePlate = Utils.removeSpecialCharacters(this.form.vehiclePlate)

    // remove html tags
    this.form.vehiclePlate = Utils.removeHTMLTags(this.form.vehiclePlate)
  }

  @Watch('form.bankAccountName')
  onChangeBankAccountName(data: string): void {
    // remove script tags
    this.form.bankAccountName = Utils.removeScriptTags(data)

    // remove special characters
    this.form.bankAccountName = Utils.removeSpecialCharacters(this.form.bankAccountName)

    // remove html tags
    this.form.bankAccountName = Utils.removeHTMLTags(this.form.bankAccountName)
  }

  @Watch('form.bankAccount')
  onChangeBankAccount(data: string): void {
    // remove script tags
    this.form.bankAccount = Utils.removeScriptTags(data)

    // remove special characters
    this.form.bankAccount = Utils.removeSpecialCharacters(this.form.bankAccount)

    // remove html tags
    this.form.bankAccount = Utils.removeHTMLTags(this.form.bankAccount)
  }

  @Watch('controller.modalSuccessAdd')
  setSuccessModal(data: boolean): void {
    if(data) {
      this.$router.push('/manage-user-&-courier/manage-courier')
    }
  }

  @Watch('controller.truckVendorIdData')
  private setTruckVendorIdData(data: TruckVendor[]): void {
      this.vendorIdOptions = data.map((item: TruckVendor) => {
        return {
          label: <string>item.vendorId,
          value: <string>item.vendorId
        }
      })
  }

  @Watch('controller.truckVendorNameData')
  private setTruckVendorNameData(data: TruckVendor[]): void {
      this.vendorNameOptions = data.map((item: TruckVendor) => {
        return {
          label: <string>item.vendorName,
          value: <string>item.vendorName
        }
      })
  }
}
